import { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { Helmet, HelmetProvider } from "react-helmet-async"

import App from "./app"
import { CONFIG } from "./config-global"

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <GoogleOAuthProvider clientId="508456680496-ig56kc5nkm3mg9lg2r03fadvcrgo6o83.apps.googleusercontent.com">
        <HelmetProvider>
            <Helmet>
                <link rel="icon" type="image/png" href={CONFIG.site.icon} />
            </Helmet>
            <BrowserRouter basename={CONFIG.site.basePath}>
                <Suspense>
                    <App />
                </Suspense>
            </BrowserRouter>
        </HelmetProvider>
    </GoogleOAuthProvider>
)
