import { useContext } from "react"
import { AppConfigContext } from "@/src/config/appConfigContext"

export function useAppConfigContext() {
    const context = useContext(AppConfigContext)

    if (!context) {
        throw new Error("useAppConfigContext: Context must be used inside AppConfigProvider")
    }

    return context
}
