import { useMemo, useEffect, useCallback } from "react"

import { useSetState } from "src/hooks/use-set-state"

import axios, { endpoints } from "src/utils/axios"

import { STORAGE_KEY } from "./constant"
import { AuthContext } from "../auth-context"
import { setSession, isValidToken } from "./utils"
import { type UserRole, type AuthState, USER_ROLE_ORDER } from "../../types"

// ----------------------------------------------------------------------

/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */

type Props = {
    children: React.ReactNode
}

export function AuthProvider({ children }: Props) {
    const { state, setState } = useSetState<AuthState>({
        user: null,
        loading: true,
    })

    const getHighestRole = (roles: UserRole[] | undefined): UserRole => {
        if (!roles) return "RECRUITER"
        // eslint-disable-next-line no-restricted-syntax
        for (const role of USER_ROLE_ORDER) {
            if (roles.includes(role)) return role
        }
        return USER_ROLE_ORDER[USER_ROLE_ORDER.length - 1]
    }

    const checkUserSession = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem(STORAGE_KEY)

            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken)

                const res = await axios.get(endpoints.auth.me)
                const user = res.data?.data

                setState({ user: { ...user, accessToken, highestRole: getHighestRole(user.roles) }, loading: false })
            } else {
                setState({ user: null, loading: false })
            }
        } catch (error) {
            console.error(error)
            setState({ user: null, loading: false })
        }
    }, [setState])

    useEffect(() => {
        checkUserSession()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ----------------------------------------------------------------------

    const checkAuthenticated = state.user ? "authenticated" : "unauthenticated"

    const status = state.loading ? "loading" : checkAuthenticated

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            checkUserSession,
            loading: status === "loading",
            authenticated: status === "authenticated",
            unauthenticated: status === "unauthenticated",
        }),
        [checkUserSession, state.user, status]
    )

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}
