import { Helmet } from "react-helmet-async"

import { CONFIG } from "src/config-global"

import { View500 } from "src/sections/error"

// ----------------------------------------------------------------------

const metadata = { title: `500 Internal server error! | Error - ${CONFIG.site.name}` }

export default function ServerError() {
    return (
        <>
            <Helmet>
                <title> {metadata.title}</title>
            </Helmet>

            <View500 />
        </>
    )
}
