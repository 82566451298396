import { useMemo } from "react"
import { Navigate, useRoutes } from "react-router-dom"
import useIsAiProcessing from "@/src/hooks/useIsAiProcessing"

import { CONFIG } from "src/config-global"

import { authRoutes } from "./auth"
import { mainRoutes } from "./main"
import { dashboardRoutes } from "./dashboard"

// ----------------------------------------------------------------------

export function Router() {
    const isAiProcessing = useIsAiProcessing()
    const primaryRoutes = useMemo(() => dashboardRoutes(isAiProcessing ?? false), [isAiProcessing])

    return useRoutes([
        {
            path: "/",
            element: (
                <Navigate
                    to={isAiProcessing ? CONFIG.auth.redirectPathAiProcessing : CONFIG.auth.redirectPath}
                    replace
                />
            ),
        },

        // Auth
        ...authRoutes,

        // Dashboard
        ...primaryRoutes,

        // Main
        ...mainRoutes,

        // No match
        { path: "*", element: <Navigate to="/404" replace /> },
    ])
}
