import "src/global.css"

// ----------------------------------------------------------------------
import * as Sentry from "@sentry/browser"
import { AppConfigProvider } from "@/src/config/appConfigProvider"

import { LicenseInfo } from "@mui/x-license"

import { Router } from "src/routes/sections"

import { useScrollToTop } from "src/hooks/use-scroll-to-top"

import { LocalizationProvider } from "src/locales"
import { ThemeProvider } from "src/theme/theme-provider"

import { ProgressBar } from "src/components/progress-bar"
import { MotionLazy } from "src/components/animate/motion-lazy"
import { SettingsDrawer, defaultSettings, SettingsProvider } from "src/components/settings"

import { AuthProvider } from "src/auth/context/jwt"

import { CONFIG } from "./config-global"
import { setTag } from "./sentry/sentry"
import { Snackbar } from "./components/snackbar"

// ----------------------------------------------------------------------
if (import.meta.env.MODE !== "development")
    Sentry.init({
        dsn: CONFIG.site.sentryUrl,
        environment: CONFIG.site.env,
        release: `ATS-PPH`,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })

if (import.meta.env.MODE !== "development")
    Sentry.configureScope((scope: any) => {
        setTag(scope)
    })

export default function App() {
    LicenseInfo.setLicenseKey(
        "efe3d5ea656d06c52dc94493de3201d2Tz05NzA2OSxFPTE3NTY0NzA5OTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
    )
    useScrollToTop()

    return (
        <LocalizationProvider>
            <AuthProvider>
                <SettingsProvider settings={defaultSettings}>
                    <ThemeProvider>
                        <AppConfigProvider>
                            <MotionLazy>
                                <ProgressBar />
                                <SettingsDrawer hidePresets hideNavColor hideContrast hideCompact hideDirection />
                                <Snackbar />
                                <Router />
                            </MotionLazy>
                        </AppConfigProvider>
                    </ThemeProvider>
                </SettingsProvider>
            </AuthProvider>
        </LocalizationProvider>
    )
}
