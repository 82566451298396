import type { IconButtonProps } from "@mui/material/IconButton"

import { m } from "framer-motion"

import Badge from "@mui/material/Badge"
import SvgIcon from "@mui/material/SvgIcon"
import IconButton from "@mui/material/IconButton"

import { useSettingsContext } from "src/components/settings/context"

// ----------------------------------------------------------------------

export type SettingsButtonProps = IconButtonProps

export function SettingsButton({ sx, ...other }: SettingsButtonProps) {
    const settings = useSettingsContext()

    return (
        <IconButton aria-label="settings" onClick={settings.onToggleDrawer} sx={{ p: 0, ...sx }} {...other}>
            <Badge color="error" variant="dot" invisible={!settings.canReset}>
                <SvgIcon component={m.svg} sx={{ height: 40, width: 40 }}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M23.123 14.2627L21.8696 14.1033C21.7663 13.7853 21.639 13.4787 21.4903 13.1873L22.2643 12.1907C22.5776 11.7873 22.541 11.218 22.183 10.8713L21.133 9.82133C20.783 9.46 20.2136 9.424 19.8096 9.73667L18.8143 10.5107C18.523 10.362 18.2163 10.2347 17.8976 10.1313L17.7383 8.88C17.6783 8.378 17.2523 8 16.7476 8H15.2543C14.7496 8 14.3236 8.378 14.2636 8.878L14.1043 10.1313C13.7856 10.2347 13.479 10.3613 13.1876 10.5107L12.1916 9.73667C11.789 9.424 11.2196 9.46 10.8723 9.818L9.82231 10.8673C9.46098 11.218 9.42431 11.7873 9.73764 12.1913L10.5116 13.1873C10.3623 13.4787 10.2356 13.7853 10.1323 14.1033L8.88098 14.2627C8.37898 14.3227 8.00098 14.7487 8.00098 15.2533V16.7467C8.00098 17.2513 8.37898 17.6773 8.87898 17.7373L10.1323 17.8967C10.2356 18.2147 10.363 18.5213 10.5116 18.8127L9.73764 19.8093C9.42431 20.2127 9.46098 20.782 9.81898 21.1287L10.869 22.1787C11.2196 22.5393 11.7883 22.5753 12.1923 22.2627L13.1883 21.4887C13.4796 21.638 13.7863 21.7653 14.1043 21.868L14.2636 23.1187C14.3236 23.622 14.7496 24 15.2543 24H16.7476C17.2523 24 17.6783 23.622 17.7383 23.122L17.8976 21.8687C18.2156 21.7653 18.5223 21.638 18.8136 21.4893L19.8103 22.2633C20.2136 22.5767 20.783 22.54 21.1296 22.182L22.1796 21.132C22.541 20.7813 22.5776 20.2127 22.2643 19.8087L21.4903 18.8127C21.6396 18.5213 21.767 18.2147 21.8696 17.8967L23.1203 17.7373C23.6223 17.6773 24.0003 17.2513 24.0003 16.7467V15.2533C24.001 14.7487 23.623 14.3227 23.123 14.2627ZM16.001 19.3333C14.163 19.3333 12.6676 17.838 12.6676 16C12.6676 14.162 14.163 12.6667 16.001 12.6667C17.839 12.6667 19.3343 14.162 19.3343 16C19.3343 17.838 17.839 19.3333 16.001 19.3333Z"
                            fill="#5E6C84"
                        />
                    </svg>
                </SvgIcon>
            </Badge>
        </IconButton>
    )
}
