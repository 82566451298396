// ----------------------------------------------------------------------

const ROOTS = {
    AUTH: "/auth",
    DASHBOARD: "/dashboard",
}

// ----------------------------------------------------------------------

export const paths = {
    faqs: "/faqs",
    minimalStore: "https://mui.com/store/items/minimal-dashboard/",
    // AUTH
    auth: {
        amplify: {
            signIn: `${ROOTS.AUTH}/amplify/sign-in`,
            verify: `${ROOTS.AUTH}/amplify/verify`,
            signUp: `${ROOTS.AUTH}/amplify/sign-up`,
            updatePassword: `${ROOTS.AUTH}/amplify/update-password`,
            resetPassword: `${ROOTS.AUTH}/amplify/reset-password`,
        },
        jwt: {
            signIn: `${ROOTS.AUTH}/jwt/sign-in`,
            signUp: `${ROOTS.AUTH}/jwt/sign-up`,
        },
        firebase: {
            signIn: `${ROOTS.AUTH}/firebase/sign-in`,
            verify: `${ROOTS.AUTH}/firebase/verify`,
            signUp: `${ROOTS.AUTH}/firebase/sign-up`,
            resetPassword: `${ROOTS.AUTH}/firebase/reset-password`,
        },
        auth0: {
            signIn: `${ROOTS.AUTH}/auth0/sign-in`,
        },
        supabase: {
            signIn: `${ROOTS.AUTH}/supabase/sign-in`,
            verify: `${ROOTS.AUTH}/supabase/verify`,
            signUp: `${ROOTS.AUTH}/supabase/sign-up`,
            updatePassword: `${ROOTS.AUTH}/supabase/update-password`,
            resetPassword: `${ROOTS.AUTH}/supabase/reset-password`,
        },
    },
    // DASHBOARD
    dashboard: {
        root: ROOTS.DASHBOARD,
        onboarding: `${ROOTS.DASHBOARD}/onboarding`,
        interviews: `${ROOTS.DASHBOARD}/interviews`,
        selections: `${ROOTS.DASHBOARD}/selections`,
        mandates: `${ROOTS.DASHBOARD}/mandates`,
        clients: `${ROOTS.DASHBOARD}/clients`,
        bulkUpload: `${ROOTS.DASHBOARD}/bulk-upload`,
        userManagement: `${ROOTS.DASHBOARD}/user-management`,
        interviewForm: `${ROOTS.DASHBOARD}/interviews/interview-form`,
        leadForm: `${ROOTS.DASHBOARD}/lead-form`,
        clientForm: `${ROOTS.DASHBOARD}/clients/client-form`,
        selectionForm: `${ROOTS.DASHBOARD}/selections/selection-form`,
        mandateForm: `${ROOTS.DASHBOARD}/mandates/mandate-form`,
        three: `${ROOTS.DASHBOARD}/three`,
        jobDetails: `${ROOTS.DASHBOARD}/jobDetails/:id`,
        group: {
            root: `${ROOTS.DASHBOARD}/group`,
            five: `${ROOTS.DASHBOARD}/group/five`,
            six: `${ROOTS.DASHBOARD}/group/six`,
        },
        client: {
            root: `${ROOTS.DASHBOARD}/client-details/:id`,
            details: (id: string) => `${ROOTS.DASHBOARD}/client-details/${id}`,
        },
        interview: {
            details: (id: string) => `${ROOTS.DASHBOARD}/interview-details/${id}`,
        },
        lead: {
            details: (id: string) => `${ROOTS.DASHBOARD}/lead-details/${id}`,
        },
        mandate: {
            root: `${ROOTS.DASHBOARD}/mandate-details/:id`,
            details: (id: string) => `${ROOTS.DASHBOARD}/mandate-details/${id}`,
        },
        selection: {
            root: `${ROOTS.DASHBOARD}/selection-details/:id`,
            details: (id: string) => `${ROOTS.DASHBOARD}/selection-details/${id}`,
        },
    },
}
