import { useAppConfigContext } from "@/src/config/useAppConfigContext"

/**
 * Hook to check if AI processing is enabled for this user.
 * @returns {(boolean|undefined)} boolean if user is logged in, undefined otherwise
 */
const useIsAiProcessing = (): boolean | undefined => {
    const { appConfig } = useAppConfigContext()
    return appConfig?.enableAIProcessing
}

export default useIsAiProcessing
