import * as Sentry from "@sentry/browser"
import { getItemFromLocalStorage } from "../utils/storage-available"

export const getReleaseVersion = () => "0.0.0.1"

export const setTag = (scope: any) => {
    try {
        const userSession = JSON.parse(getItemFromLocalStorage("__user__") || "null")
        if (userSession) {
            scope.setTag("user_Id", userSession.id || "NA")
            scope.setTag("phone_number", userSession.phone_number || "NA")
            scope.setTag("is_internal_user", userSession.is_internal_user || "NA")
            scope.setUser({
                id: userSession.id || "NA",
                phone_number: userSession.phone_number,
                name: userSession.full_name,
                email: userSession.email,
                is_internal_user: userSession.is_internal_user,
            })
        }
    } catch (error) {
        Sentry.captureException(error)
    }
}
